/* Services Offered Page Styling */
.services-offered {
  background: linear-gradient(135deg, #7d8002, #ca0822, #06088b);
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 100px 20px 20px;
  box-sizing: border-box;
  margin: 0 auto;
}

/* Content container to limit width and center content */
.services-container {
  max-width: 1000px; /* Limits text width */
  width: 100%;
  text-align: left;
}

/* Center-align the main title */
.services-offered h1 {
  text-align: center;
  font-size: 3rem;
  color: #39e90d; /* Bright color for visibility */
  margin-bottom: 20px;
}

/* Center-align section headings */
.services-offered h2, .services-offered h3 {
  text-align: center;
  font-size: 2rem;
  color: #e2f108; /* Lighter shade for contrast */
  margin-bottom: 15px;
}

/* Ensure content text is left-aligned within the container */
.services-container p,
.services-container li {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: left;
}

/* Prevent nested elements from inheriting background gradient */
.services-container p,
.services-container ol,
.services-container ul {
  background: none;
}

/* Remove bullets from lists */
.services-container ul,
.services-container ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  body {
    background: linear-gradient(to bottom, rgb(112, 16, 99), rgb(86, 59, 240));
    background-attachment: fixed;
    background-size: cover;
  }
    
  .services-offered {
      padding: 60px 10px 10px; /* Reduced padding for mobile */
  }

  .services-container {
      padding: 10px; /* Added padding for better spacing */
  }

  .services-offered h1 {
      font-size: 2rem; /* Reduced font size for h1 */
      margin-bottom: 15px;
  }

  .services-offered h2, .services-offered h3 {
      font-size: 1.5rem; /* Reduced font size for h2, h3 */
      margin-bottom: 10px;
  }

  .services-container p,
  .services-container li {
      font-size: 1.1rem; /* Adjusted font size for readability */
      margin-bottom: 15px;
  }

  .services-container ul,
  .services-container ol {
      margin-left: 20px; /* Added slight indentation for list items */
  }
}
