/* Container for centering */
.change-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f4f4; /* Example background */
    padding: 20px;
}

/* Styles for the form itself */
.change-password-form {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 350px; /* Adjust as needed */
}

/* Form heading */
.change-password-form h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

/* Input fields */
.change-password-form input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Ensures padding does not affect width */
}

/* Password Strength Section */
.password-strength-container {
    margin-top: 10px;
}

/* Strength Indicator Bar */
.password-strength {
    height: 10px;
    width: 100%;
    background-color: lightgray;
    border-radius: 2px;
    transition: width 0.3s ease-in-out;
}
.strength-0, .strength-1, .strength-2, .strength-3, .strength-4 {
    height: 10px; /* Ensure color bars match the thickness of the gray bar */
    border-radius: 2px;
}
/* Strength levels */
.strength-0 { width: 20%; background-color: red; }
.strength-1 { width: 40%; background-color: orange; }
.strength-2 { width: 60%; background-color: yellow; }
.strength-3 { width: 80%; background-color: green; }
.strength-4 { width: 100%; background-color: green; }

/* Password Strength Text */
.password-strength-text {
    font-size: 12px;
    margin-top: 5px;
    color: #333;
}

/* Password Requirements List */
.password-requirements {
    margin-top: 45px; /* Adjusted spacing to prevent overlap */
    font-size: 14px;
    color: #555;
}

.password-requirements h4 {
    font-weight: bold;
}

/* List Style */
.password-requirements ul {
    list-style-type: none;
    padding: 0;
}

/* Individual Requirement */
.password-requirements ul li {
    margin-bottom: 5px;
}

/* Checkmark for met requirements */
.password-requirements ul li::before {
    content: "✔️ ";
    color: green;
}

/* Error messages */
.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

/* Submit button */
.change-password-form button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.change-password-form button:hover {
    background-color: #0056b3;
}

/* Optional: Responsive Styles */
@media (max-width: 400px) {
    .change-password-form {
        width: 90%;
        padding: 20px;
    }
}

@media screen and (max-width: 768px) {
    /* Ensure form starts below navbar */
    .change-password-container {
        padding-top: 100px; /* Prevents overlap with navbar */
        padding-left: 10px;
        padding-right: 10px;
    }

    /* Adjust form width and padding */
    .change-password-form {
        width: 100%;
        max-width: 320px; /* Slightly smaller for better layout */
        padding: 20px;
    }

    /* Reduce padding for input fields */
    .change-password-form input[type="password"] {
        padding: 8px;
    }

    /* Adjust button size */
    .change-password-form button {
        padding: 12px;
        font-size: 1rem;
    }
}
