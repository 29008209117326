/* About Us Page Styling */
.about-us {
  background: linear-gradient(135deg, #7d8002, #ca0822, #06088b);
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 100px 20px 20px;
  box-sizing: border-box;
  text-align: center;
}

/* Wrapper to restrict text width */
.about-us-content {
  max-width: 1000px; /* Limit text width */
  width: 100%; /* Ensure responsiveness */
  margin: 0 auto; /* Center the content */
  padding: 20px; /* Adds some inner spacing */
}

.about-us h1 {
  text-align: center;
    font-size: 3rem;
    color: #39e90d; /* Gold or bright color for visibility */
    margin-bottom: 20px;
}

.h1-text {
  color: #e9da0d !important; /* Forces color to match <h1> */
}




.about-us h2, .about-us h3 {
  text-align: center;
    font-size: 2rem;
    color: #e2f108; /* Lighter shade for subtle contrast */
    margin-bottom: 15px;
}

.about-us p {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
}

/* Author Image Styling */
.author-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  margin: 0 20px 20px 0;
}

@media (max-width: 768px) {
  body {
    background: linear-gradient(to bottom, rgb(112, 16, 99), rgb(86, 59, 240));
    background-attachment: fixed;
    background-size: cover;
    overflow-x: hidden; /* Prevents horizontal scrolling */
  }

  /* Ensure all content fits within the screen */
  .about-us {
    padding: 80px 15px 15px; /* Reduce top padding */
    max-width: 100%; /* Prevents content from overflowing */
    overflow-x: hidden; /* Ensures no horizontal scroll */
    word-wrap: break-word; /* Breaks long words */
  }

  /* Make headings smaller on mobile */
  .about-us h1 {
    font-size: 2rem;
    text-align: center; /* Center align for better readability */
  }

  .about-us h2, .about-us h3 {
    font-size: 1.5rem;
    text-align: center;
  }

  /* Reduce paragraph font size for better fit */
  .about-us p {
    font-size: 1rem;
    line-height: 1.5;
    text-align: justify;
    max-width: 100%;
    padding: 0 10px; /* Adds spacing on the sides to avoid overflow */
  }

  /* Ensure images do not overflow */
  .author-image {
    width: 120px;
    height: 120px;
    margin: 10px auto;
    display: block;
    max-width: 100%;
  }
}



