/* Navbar container */
.navbar {
  position: fixed;
  top: 40px;
  left: 0;
  width: 100%;
  background-color: rgb(88, 13, 86);
  padding: 10px 20px;
  z-index: 10000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

/* Hamburger menu */
.menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menu-toggle div {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 5px 0;
  transition: transform 0.3s ease;
}

.menu-toggle.open div:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.menu-toggle.open div:nth-child(2) {
  opacity: 0;
}

.menu-toggle.open div:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

/* Navbar links */
.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
}

.nav-links li {
  padding: 10px 15px;
  cursor: pointer;
}

.nav-links li a {
  text-decoration: none;
  color: white; /* Light shade */
  font-weight: bold;
  font-size: 16px;
  display: block;
}

.nav-links li:hover {
  background-color: #b74ac7;
}

/* Mobile styles */
@media (max-width: 768px) {
  .menu-toggle {
    display: flex;
  }

  .nav-links {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: rgb(88, 13, 86);
    flex-direction: column;
    display: none;
    align-items: flex-start; /* Left align in mobile */
    padding-left: 20px; /* Ensure padding for left alignment */
  }

  .nav-links.active {
    display: flex;
  }

  .nav-links li {
    width: 100%;
    text-align: left; /* Ensures text is left-aligned */
    padding: 15px;
  }
}
