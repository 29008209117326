/* Full-page gradient background */
.contact-us-container {
    display: flex;
    justify-content: center; /* Centers content horizontally */
    width: 100%;
    min-height: 100vh;
    padding: 0; /* Remove extra padding */
    background: linear-gradient(135deg, #7d8002, #ca0822, #06088b); /* Gradient remains full-page */
  }
  
  /* Centers and limits the content */
  .contact-us {
    max-width: 1000px; /* Matches Services & Pricing pages */
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    padding: 50px 20px 20px; /* Matches spacing from other pages */
    box-sizing: border-box;
    margin-top: 60px; /* Adjust to match other pages' top spacing */
  }

.contact-us h2 {
  text-align: center;
  font-size: 3rem;
  color: #39e90d; /* Bright color for visibility */
  margin-bottom: 20px;
}

.contact-us p {
    font-size: 1.5rem;
    color: #ffffff;
    line-height: 1.5;
    margin-bottom: 15px;
}

.contact-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.contact-buttons a {
    display: inline-block;
    width: 250px;
    padding: 12px 15px;
    margin: 10px 0;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.contact-buttons a:hover {
    background-color: #0056b3;
}

.tech-support-link {
    display: block;
    text-align: center;
    color: #007bff;
    text-decoration: none;
    cursor: pointer;
    margin: 10px auto; /* Optional: Adds spacing */
}


.tech-support-link:hover {
    text-decoration: underline;
}


/* Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 500px;
    max-width: 90%;
    position: relative;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    color: #888;
}

.modal label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.modal input,
.modal textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.modal textarea {
    resize: vertical;
}

.modal button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.modal button:hover {
    background-color: #0056b3;
}

/* Responsive Styles (Optional) */
@media (max-width: 768px) {
    body {
      background: linear-gradient(to bottom, rgb(112, 16, 99), rgb(86, 59, 240));
      background-attachment: fixed;
      background-size: cover;
    }
  }
    .modal-content {
        width: 90%;
    }


@media screen and (max-width: 768px) {
    /* Ensure content starts below the navbar */
    .contact-us {
        margin-top: 80px; /* Adjusted to prevent overlap */
        padding: 40px 15px; /* Reduce padding for better spacing */
        text-align: center; /* Center text on smaller screens */
    }

    /* Reduce title font size for better fit */
    .contact-us h2 {
        font-size: 2rem;
    }

    /* Adjust paragraph font size for readability */
    .contact-us p {
        font-size: 1.2rem;
        line-height: 1.4;
    }

    /* Adjust button width and spacing */
    .contact-buttons a {
        width: 90%;
        padding: 10px;
        font-size: 1rem;
    }

    /* Ensure modal fits within the screen */
    .modal-content {
        width: 95%;
        padding: 15px;
    }

    /* Adjust modal input and button sizes */
    .modal input,
    .modal textarea {
        padding: 8px;
    }

    .modal button {
        padding: 12px;
        font-size: 1rem;
    }
}
