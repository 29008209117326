/* Ensure full-page layout */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

/* Main content should expand */
.main-content {
    flex: 1;
}

/* Fixed footer at the bottom */
.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25px; /* Thinner bar */
    background-color: black;
    color: white;
    text-align: center;
    font-family: Arial, sans-serif;
    font-size: 14px;
    padding: 3px 0; /* Minimal padding */
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap; /* Prevents text from wrapping */
    overflow: hidden;
}

/* Footer container should be in a single row */
.footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row; /* Ensure horizontal layout */
    width: 100%;
    max-width: 1200px;
    margin: auto;
    gap: 15px;
}

/* Footer sections are inline */
.footer-section {
    flex: none;
    font-size: 14px;
    text-align: center;
    padding: 0 10px;
}

/* Remove extra margins/paddings */
.footer-copyright {
    margin: 0;
    padding: 0;
    font-size: 14px;
}

/* Footer Links */
.footer-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 15px;
}

.footer-section ul li {
    display: inline;
}

.footer-section ul li a {
    font-size: 14px;
    color: #bbb;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}

.footer-section ul li a:hover {
    color: white;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .footer-container {
        flex-direction: row; /* Ensures text stays in a single line */
        align-items: center;
        text-align: center;
        gap: 10px;
        flex-wrap: nowrap;
        overflow: hidden;
    }
    .footer {
        height: 20px; /* Slightly taller for better readability on mobile */
        padding: 2px 0;
    }
}
