.home-page {
  background: linear-gradient(135deg, #7d8002, #ca0822, #06088b);
  background-attachment: contain;
  background-blend-mode: lighten;
  min-height: 100vh; /* Ensures full viewport height */
  padding-top: 70px; /* Push content down to avoid navbar overlap */


    
  color: #222; /* Darker text for better contrast */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 40px;
  position: relative;
  padding-top: 70px; /* Adjust this value based on your navbar height */
}


.hero-section {
  padding: 60px;
  width: 100%; /* Ensure the section takes the full width */
  max-width: 1200px; /* Limit the max width of the section */
}

.hero-section h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  text-align: center; /* Center align h2 */
  color: #39e90d; /* Gold or bright color for visibility */ 
  width: 100%;
}

.hero-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center; /* Center align h2 */
  color: #e2f108; /* Lighter shade for subtle contrast */
  width: 100%;
}

.hero-section p {
  font-size: 1.5rem;
  margin-bottom: 40px;
  max-width: 1000px;
  margin: 0 auto;
  text-align: left; /* Left align p */
  color: rgb(255, 255, 255); /* Black color for p */
}

.visual-section {
  margin: 40px 0;
}

.visual-section img {
  max-width: 300px; /* Limit the image width to 300px */
  width: 100%; /* Make sure the image is responsive */
  height: auto;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

/* Center the hero image */
.hero-image-container {
  display: flex;
  justify-content: center; /* Center the image horizontally */
  margin: 20px 0; /* Add some space around the image */
}

.hero-image {
  max-width: 80%; /* Ensures image scales properly */
  height: auto; /* Maintain aspect ratio */
}

.footer {
  margin-top: auto;
  padding: 20px;
}

.footer p {
  font-size: 2rem;
  margin-bottom: 40px;
}

.footer button {
  padding: 15px 30px;
  font-size: 1.1rem;
  background-color: white;
  color: black;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.footer button:hover {
  background-color: #f1f1f1;
}

.footer button:mouseout {
  background-color: white;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .home-page {
    padding: 30px; /* Reduced padding for smaller devices */
  }

  .hero-section h1 {
    font-size: 2.5rem; /* Adjusted font size for smaller screens */
  }

  .hero-section h2 {
    font-size: 1.8rem; /* Reduced font size for h2 */
    margin-bottom: 10px; /* Reduced margin for h2 */
  }

  .hero-section p {
    font-size: 1.2rem; /* Reduced font size for p */
    margin-bottom: 20px; /* Reduced margin for p */
  }

  .footer p {
    font-size: 1.3rem; /* Reduced font size for footer text */
  }

  .visual-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px; /* Adjusted height for visual section */
  }

  .carousel-image {
    width: 100%;
    max-width: 300px; /* Adjust max width for images */
    height: auto;
    border-radius: 10px;
    transition: opacity 1s ease-in-out;
  }

  .home-image {
    display: block; /* Ensures margin auto works */
    margin: 0 auto; /* Centers horizontally */
    width: 100%;
    max-width: 300px; /* Adjust max width for images */
    height: auto;
    border-radius: 10px;
    transition: opacity 1s ease-in-out;
  }

  /* Reduce the padding in hero-section */
  .hero-section {
    padding: 30px; /* Adjusted padding for smaller screens */
  }
}
