/* Login.css */

.login-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    min-height: 100vh; /* Ensure full viewport height */
    background: linear-gradient(135deg, #7d8002, #ca0822, #06088b); /* Background color (adjust as needed) */
}

.login-form {
    background-color: #ffffff; /* Form background color */
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 350px; /* Adjust the width of the form */
}

.login-form h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #154add; /* Heading color */
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555; /* Label color */
}

input[type="text"],
input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Include padding and border in width */
}

button {
    width: 100%;
    padding: 10px;
    background-color: #fbff00; /* Button background color */
    color: #ffffff;
    border: none;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth hover effect */
}

button:hover {
    background-color: #e366ee; /* Darker background on hover */
}

.error-message {
    color: red;
    margin-top: 10px;
    text-align: center; /* Center the error message */
}

.password-strength {
    margin-top: 10px;
  }
  
  .strength-bar {
    height: 5px;
    width: 100%;
    background-color: lightgray;
  }
  
  .strength-0 {
    background-color: red;
  }
  
  .strength-1 {
    background-color: orange;
  }
  
  .strength-2 {
    background-color: yellow;
  }
  
  .strength-3 {
    background-color: green;
  }
  
  .strength-4 {
    background-color: green;
  }
  .password-requirements {
    margin-top: 20px;
    font-size: 14px;
  }
  
  .password-requirements h4 {
    font-weight: bold;
  }
  
  .password-requirements ul {
    list-style-type: none;
    padding: 0;
  }
  
  .password-requirements ul li {
    margin-bottom: 5px;
  }
  
  .password-requirements ul li::before {
    content: "✔️ ";
    color: green;
  }
  
  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 10px;
  }
    

/* Optional: Add some responsive styles */
@media (max-width: 400px) { /* Adjust breakpoint as needed */
    .login-form {
        width: 90%; /* Make the form take up more of the screen width */
        padding: 20px;
    }
}