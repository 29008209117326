/* BulkAgentForm.css */

.bulk-agent-form-container { /* Container for centering */
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f4f4; /* Example background */
    padding: 20px;
}

.bulk-agent-form { /* Styles for the form itself */
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 400px; /* Adjust as needed */
    max-width: 90%; /* For smaller screens */
}

.bulk-agent-form label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

.bulk-agent-form input[type="file"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.bulk-agent-form button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.bulk-agent-form button:hover {
    background-color: #0056b3;
}

/* Optional: Responsive Styles */
@media (max-width: 400px) {
    .bulk-agent-form {
        width: 90%;
        padding: 20px;
    }
}

@media screen and (max-width: 768px) {
    /* Ensure form starts below navbar */
    .bulk-agent-form-container {
        padding-top: 100px; /* Prevents overlap with navbar */
        padding-left: 10px;
        padding-right: 10px;
    }

    /* Adjust form width and padding */
    .bulk-agent-form {
        width: 100%;
        max-width: 380px; /* Slightly smaller for better layout */
        padding: 20px;
    }

    /* Reduce padding for input fields */
    .bulk-agent-form input[type="file"] {
        padding: 8px;
    }

    /* Adjust button size */
    .bulk-agent-form button {
        padding: 12px;
        font-size: 1rem;
    }
}
