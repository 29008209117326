/* Agent Dashboard Page Styling */
body {
  background: linear-gradient(135deg, #7d8002, #ca0822, #06088b);
  margin: 0;
  font-family: Arial, sans-serif;
}

.agents-dashboard-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #baeaf7; /* White background for the content */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding-top: 60px; /* Adjust this value to match your navbar height */
  margin-top: 80px; /* Increased to ensure space for mobile menu */
  position: relative; /* Prevent overlap with dropdown */
  z-index: 1; /* Ensure it stays below the navbar */
}

h2 {
  text-align: center;
  color: #3a8eee;
}

/* Table styling */
.agents-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.agents-table th,
.agents-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

/* Header row background color */
.agents-table th {
  background-color: rgb(112, 16, 99);
  text-align: center;
  color: white;
}

/* Alternate row colors */
.agents-table tr:nth-child(odd) {
  background-color: #cff8ff; /* Light gray for odd rows */
}

.agents-table tr:nth-child(even) {
  background-color: #ffffff; /* White for even rows */
}

/* Hover effect for rows */
.agents-table tr:hover {
  background-color: #f3efbc; /* Light blue on hover */
}

.chart-container {
  width: 400px; /* Set a fixed width */
  height: 400px; /* Set a fixed height */
  margin: 0 auto; /* Center the chart */
}

.download-button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.download-button {
  background-color: rgb(39, 41, 187);
  color: black;
  padding: 10px 15px;
  border-radius: 30px;
  font-weight: bold;
  text-decoration: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #5ec7f7;
}

/* Fix navbar and menu overlapping issues */
@media (max-width: 768px) {
  body {
    background: linear-gradient(to bottom, rgb(112, 16, 99), rgb(86, 59, 240));
    background-attachment: fixed;
    background-size: cover;
  }

  /* Ensure proper spacing below navbar */
  .agents-dashboard-container {
      padding: 15px;
      margin-top: 100px; /* Increased to ensure dropdown menu has space */
  }

  /* Adjust heading size */
  h2 {
      font-size: 1.5rem;
  }

  /* Make the table responsive */
  .agents-table {
      display: block;
      overflow-x: auto;
      white-space: nowrap; /* Prevent text from wrapping */
  }

  .agents-table th,
  .agents-table td {
      padding: 6px; /* Reduce padding for compact view */
      font-size: 0.9rem; /* Adjust font size for readability */
  }

  /* Resize chart container */
  .chart-container {
      width: 100%;
      height: auto;
      max-width: 300px; /* Limit width on small screens */
      max-height: 300px;
  }

  /* Fix navbar dropdown overlapping content */
  .nav-links {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: rgb(88, 13, 86);
      z-index: 100000; /* Ensure it appears above all content */
      display: none;
  }

  .nav-links.open {
      display: flex;
      flex-direction: column;
  }

  /* Ensure menu items are clickable */
  .nav-links li {
      display: block;
      padding: 10px 20px;
  }
}
