/* File Download Start */

.file-download-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 40px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 80px; /* Ensures the box starts below the navbar */
}

h2 {
    text-align: center;
    color: #333;
}

input[type="file"],
input[type="text"] {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* File Name Display */
.file-name {
    font-size: 14px;
    color: #555;
    margin-top: 5px;
    word-break: break-word; /* Ensures long filenames wrap properly */
}

/* Language & Fund Type Selection */
.language-selection,
.fund-type-selection {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-wrap: wrap; /* Ensures proper layout on smaller screens */
}

.language-selection label,
.fund-type-selection label {
    display: flex;
    align-items: center;
    margin-right: 15px; /* Adds space between radio button options */
}

.language-selection input[type="radio"],
.fund-type-selection input[type="radio"] {
    margin-right: 5px;
}

/* Button Styling */
button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
}

/* Button Disabled State */
button:disabled {
    background-color: #a0a0a0;
    cursor: not-allowed;
}

/* Navbar Styles */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}

/* Progress Bar Style */
.progress-bar {
    width: 100%;
    height: 10px;
    background-color: #ddd;
    margin-top: 10px;
    border-radius: 4px;
    overflow: hidden; /* Prevents overflow effects */
}

.progress {
    height: 100%;
    background-color: #4caf50;
    transition: width 0.2s ease;
}

/* Loading Overlay */
.loading-overlay {
    position: fixed;
    top: 80px; /* Adjusted to ensure it's below the navbar */
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .file-download-container {
        padding: 30px;
        margin-top: 100px; /* Ensuring proper spacing for smaller screens */
        width: 90%;
        max-width: 500px;
    }

    button {
        padding: 12px;
        font-size: 1rem;
    }

    input[type="file"],
    input[type="text"] {
        padding: 8px;
    }

    .language-selection,
    .fund-type-selection {
        flex-direction: column;
        align-items: flex-start;
    }

    .language-selection label,
    .fund-type-selection label {
        margin-bottom: 10px;
    }

    .language-selection input[type="radio"],
    .fund-type-selection input[type="radio"] {
        margin-right: 10px;
    }
}

/* File Download End */
