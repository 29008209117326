/* AgentForm.css */

.agent-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f4f4;
    padding: 20px;
    padding-top: 80px; /* Adjust this value to match your navbar height */

}

.agent-form {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 500px; /* Adjust as needed */
    max-width: 90%; /* For smaller screens */
}

.agent-form input,
.agent-form select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.agent-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.agent-form input[type="file"] {
    margin-bottom: 15px; /* Add margin for file inputs */
}

.agent-form button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.agent-form button:hover {
    background-color: #0056b3;
}

/* Optional: Responsive Styles */
@media (max-width: 600px) {
    .agent-form {
        width: 90%;
        padding: 20px;
    }
}

@media screen and (max-width: 768px) {
    /* Ensure form starts below navbar */
    .agent-form-container {
        padding-top: 100px; /* Adjust to prevent overlap with navbar */
        padding-left: 10px;
        padding-right: 10px;
    }

    /* Adjust form width and padding */
    .agent-form {
        width: 100%;
        max-width: 400px; /* Limit form width for better readability */
        padding: 20px;
    }

    /* Reduce input padding and margins */
    .agent-form input,
    .agent-form select {
        padding: 8px;
        margin-bottom: 10px;
    }

    /* Make the button more accessible */
    .agent-form button {
        padding: 12px;
        font-size: 1rem;
    }
}
