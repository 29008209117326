.last-login-container {
    color: white; /* Or a color that contrasts with the navbar background */
    margin-left: 20px; /* Space from other nav items */
    font-size: 14px; /* Adjust font size */
    white-space: nowrap; /* Prevent wrapping */
}

.last-login-container span { /* Style for the date/time part */
    font-weight: bold;
}

/* Optional: Add a loading indicator */
.last-login-container .loading-indicator {
    opacity: 0.5; /* Slightly less visible */
    font-style: italic;
}