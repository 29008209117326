/* ContactUs.css */
.pricing-container {
    display: flex;
    justify-content: center; /* Center container horizontally */
    align-items: flex-start; /* Start content from the top */
    min-height: 100vh; /* Full viewport height */
    background: linear-gradient(135deg, #7d8002, #ca0822, #06088b); /* Background gradient */
    padding: 100px 20px 20px; /* Adds space for navbar at the top */
    color: #fff; /* Adjust text color for readability */
    font-family: sans-serif;
}

.pricing {
    max-width: 1000px; /* Limit content width for readability */
    width: 100%;
}

.pricing h1 {
    text-align: center;
    font-size: 3rem;
    color: #39e90d; /* Gold or bright color for visibility */
    margin-bottom: 20px;
}

.pricing h2 {
    text-align: center;
    font-size: 2rem;
    color: #e2f108; /* Lighter shade for subtle contrast */
    margin-bottom: 15px;
}

.pricing p {
    text-align: left;
    font-size: 1.5rem;
    line-height: 1.6;
    color: #f2f2f2; /* Soft white for better readability */
    margin-bottom: 10px;
}

.sales-link {
    color: #ffcc00; /* Bright color to stand out */
    text-decoration: none;
    font-weight: bold;
}

.sales-link:hover {
    text-decoration: underline;
}




/* Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 500px;
    max-width: 90%;
    position: relative;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    color: #888;
}

.modal label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.modal input,
.modal textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.modal textarea {
    resize: vertical;
}

.modal button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.modal button:hover {
    background-color: #0056b3;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    body {
        background: linear-gradient(to bottom, rgb(112, 16, 99), rgb(86, 59, 240));
        background-attachment: fixed;
        background-size: cover;
      }
    
    .pricing-container {
        flex-direction: column; /* Stack items vertically */
        padding: 60px 10px 10px; /* Reduced padding for mobile */
    }
    

    .pricing {
        padding: 10px; /* Added padding for better spacing */
    }

    .pricing h1 {
        font-size: 2rem; /* Reduced font size for h1 */
        margin-bottom: 15px;
    }

    .pricing h2 {
        font-size: 1.5rem; /* Reduced font size for h2 */
        margin-bottom: 10px;
    }

    .pricing p {
        font-size: 1.2rem; /* Adjusted font size for readability */
        margin-bottom: 15px;
    }

    .sales-link {
        font-size: 1.2rem; /* Slightly larger for better visibility */
    }

    .modal-content {
        width: 90%; /* Ensure modal is full-width on smaller screens */
    }
}

