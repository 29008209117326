/* Horizontal Navigation Bar Start */
.horizontal-navbar {
  position: fixed;
  top: 40px; /* Adjusted to allow more space for the navbar */
  width: 100%;
  z-index: 99999; /* Increased z-index to ensure visibility above other elements */
  background-color: rgb(112, 16, 99);
  padding: 15px 20px; /* Increased padding for better spacing */
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 20px; /* Adjusted height */
}

/* Ensure Body Doesn't Overlap Navbar */
body {
  margin: 0;
  padding-top: 0px !important; /* Adjusted to prevent overlap */
}

/* Navbar Links */
.horizontal-navbar .nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
  align-items: center; /* Align items to the bottom */
}

.horizontal-navbar .nav-links li {
  display: inline;
}

/* Navbar Link Styling */
.horizontal-navbar .nav-links a {
  color: #fff;
  text-decoration: none;
  font-size: 1.1rem;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  display: inline-block;
  vertical-align: bottom;
}

.horizontal-navbar .nav-links a:hover {
  background-color: #4de0fa;
}

/* Active Page Styling */
.horizontal-navbar .nav-links a.active {
  background-color: #007bff;
  color: #fff;
}

/* Ensure Login Button Stays on Right */
.nav-links .login-button {
  background-color: rgb(86, 59, 240);
  color: black;
  padding: 10px 15px;
  border-radius: 30px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease;
  border: none;
  cursor: pointer;
  white-space: nowrap; /* Prevent button from stretching */
  text-align: center;
  width: 120px; /* Fixed width for uniform button size */
}

.nav-links .login-button:hover {
  background-color: #f4f76c;
}

/* Push Login Button to Extreme Right */
.horizontal-navbar .login-button-container {
  margin-left: auto;
  align-self: flex-end; /* Align login button to the bottom */
}

/* Google Translate Wrapper */
.translate-wrapper {
  position: fixed;
  top: 90px; /* Adjusted to make sure it stays visible and doesn't overlap the navbar */
  right: 5px; /* Align to the right */
  z-index: 10000; /* Ensure it's always above other elements */
  background: white;
  padding: 5px;
  border-radius: 5px;
}

/* Prevent Google Translate from overriding styles */
.goog-te-gadget {
  font-size: 0 !important; /* Hide extra unwanted text */
  white-space: nowrap !important; /* Prevent wrapping issues */
}

/* Hide Google Translate Banner */
.goog-te-banner-frame {
  display: none !important;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .horizontal-navbar {
    flex-direction: row;
    justify-content: flex-start; /* Align everything to the left */
    align-items: center;
    padding: 5px 10px;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  /* Hamburger Menu Icon */
  .menu-icon {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin-left: 15px; /* Moves the menu icon to the left */
  }

  .menu-icon div {
    width: 30px;
    height: 4px;
    background-color: white;
    margin: 6px 0;
    border-radius: 3px;
    transition: 0.3s;
  }

  /* Menu icon animation when open */
  .menu-icon.open div:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .menu-icon.open div:nth-child(2) {
    opacity: 0;
  }

  .menu-icon.open div:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  /* Navbar Links */
  .horizontal-navbar .nav-links {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: rgb(112, 16, 99);
    flex-direction: column;
    display: none;
    align-items: flex-start;
    padding-left: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    gap: 5px; /* Reduces spacing between links */
  }

  /* Show menu when active */
  .horizontal-navbar.active .nav-links {
    display: flex;
  }

  /* Align links properly */
  .horizontal-navbar .nav-links a {
    font-size: 1rem;
    display: block;
    padding: 8px 15px; /* Reduced padding */
    text-align: left; /* Align text to the left */
    width: 100%;
  }

  /* Login Button Positioned Above Other Links */
  .horizontal-navbar .login-button-container {
    order: -1; /* Moves login button to the top */
    text-align: left;
    padding-left: 10px; /* Align login button to the left */
    width: 100%;
  }

  .horizontal-navbar .login-button {
    display: block; /* Ensures proper width */
    padding: 10px 15px;
    font-size: 1rem;
    background-color: #213fe9;
    color: #ffffff;
    border-radius: 5px;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    width: 20px;
    height: 40px; /* Ensures text is visible */
    line-height: 40px; /* Centers text vertically */
  }

  /* Close menu when clicking outside */
  .horizontal-navbar.active .nav-links {
    display: flex;
  }

  /* Google Translate Wrapper - Moved Below Hamburger */
  .translate-wrapper {
    position: absolute;
    top: 55px; /* Places it below the hamburger */
    left: 10px; /* Aligns it with the menu */
    background: white;
    padding: 5px;
    border-radius: 5px;
    z-index: 10000;
  }
}

/* Horizontal Navigation Bar End */
